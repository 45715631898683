import { Typography } from 'shared/ui/typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Divider } from '@mui/material';
import { ReturnType } from 'shared/hooks/useBoolean';
import styles from './style.module.scss';
import { ByMoneyMovement } from '../byMoneyMovement';
import { ByTransferType } from '../byTransferType';
import { FormValues } from '..';

interface FilterFormProps {
  formik: FormikProps<FormValues>;
  expandByMoneyMovement: ReturnType;
  expandByTransferType: ReturnType;
  onReset: () => void;
}

export const FilterForm = (props: FilterFormProps) => {
  const { formik, expandByMoneyMovement, expandByTransferType, onReset } =
    props;

  return (
    <form
      className={styles.container}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.box}>
        <Typography>Date range</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            disableFuture
            localeText={{ start: 'From', end: 'To' }}
            calendars={1}
            value={formik.values.date}
            onChange={(newDateRange) => {
              formik.setFieldValue('date', newDateRange);
            }}
            slotProps={{
              textField: {
                error: !!formik.errors.date,
              },
              fieldSeparator: {
                display: 'none',
              },
              fieldRoot: {
                direction: 'column',
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className={styles.box}>
        <Typography>Amount range</Typography>
        <CurrencyInput2
          fullWidth
          variant='outlined'
          label='From'
          placeholder='$0.00'
          getCaretBoundary={() => [false]}
          name='fromAmount'
          value={formik.values.fromAmount}
          onChange={(value) => {
            formik.setFieldValue('fromAmount', value);
          }}
          onBlur={formik.handleBlur}
        />
        <CurrencyInput2
          fullWidth
          variant='outlined'
          label='To'
          placeholder='$0.00'
          getCaretBoundary={() => [false]}
          name='toAmount'
          value={formik.values.toAmount}
          onChange={(value) => {
            formik.setFieldValue('toAmount', value);
          }}
          onBlur={formik.handleBlur}
          error={formik.errors.toAmount?.toString()}
        />
      </div>
      <Divider />
      <ByMoneyMovement
        formik={formik}
        expandByMoneyMovement={expandByMoneyMovement}
      />
      <Divider />
      <ByTransferType
        formik={formik}
        expandByTransferType={expandByTransferType}
      />
      <div className={styles.boxBtn}>
        <LoadingButton
          variant='text'
          onClick={() => onReset()}
        >
          <div>clear all</div>
        </LoadingButton>
        <LoadingButton
          type='submit'
          className={styles.applyBtn}
          variant='contained'
          disabled={Object.values(formik.errors).some(Boolean)}
        >
          <div>apply filter</div>
        </LoadingButton>
      </div>
    </form>
  );
};
