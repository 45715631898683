import { LocalStorageKeys, setLocalStorageObject } from '../utils/local-storage';
import helper from '../../services/helper';
import { useGetAuthConnectedAccountControllerMutation, useGetAuthConnectedAccountReturnControllerMutation } from '../../api/endpoints/auth';
import { useSnackBar } from '../../providers/snackBar.provider';
import { LinkedAccountApi, LinkedAccountMeApi, useLinkedAccountControllerQuery, useLinkedAccountMeControllerQuery } from '../../api/endpoints/linkedAccount';
import { useConfirmDialog } from '../../providers/confirm.provider';
import { requireNotNull } from '../utils/require-not-null';
import { useTranslationProvider } from '../../providers/translation/translation.provider';

interface ReturnType {
  backToMyAccount(): Promise<void>;
  loginAsAccount(accountId: number): void;

  userLinkedAccounts: LinkedAccountMeApi[] | undefined;
  isUserLinkedAccountsLoading: boolean;

  userConnectedAccounts: LinkedAccountApi[] | undefined;
  isUserConnectedAccountsLoading: boolean;

  connectedAccountsToShow: LinkedAccountApi[] | undefined;
}

export const useLoginManagement = (): ReturnType => {
  const confirm = useConfirmDialog();
  const { setSnackBar } = useSnackBar();
  const { t } = useTranslationProvider();

  const {
    data: userConnectedAccounts,
    isFetching: isUserConnectedAccountsLoading,
  } = useLinkedAccountControllerQuery({});

  const {
    data: userLinkedAccounts,
    isFetching: isUserLinkedAccountsLoading,
  } = useLinkedAccountMeControllerQuery({});

  const [getAuthConnectedAccountMutation] =
    useGetAuthConnectedAccountControllerMutation();

  const [getAuthConnectedAccountReturnMutation] =
    useGetAuthConnectedAccountReturnControllerMutation();

  const backToMyAccount = async () => {
    try {
      const res = await getAuthConnectedAccountReturnMutation({}).unwrap();
      const token = res?.token;

      if (token) {
        setLocalStorageObject(LocalStorageKeys.AuthToken, token);
        window.location.href = "/home";
      }
    } catch (e: any) {
      console.log("error", e);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const loginAsAccount: ReturnType['loginAsAccount'] = (accountId: number) => {
    const userDBA = requireNotNull(userConnectedAccounts?.find(account => account.id === accountId)?.dba)
    confirm.show({
      dialogTitle: "Account switch confirmation",
      dialogText: `You are about to switch to “${userDBA}”. This action will log you out of your current account.`,
      applyButtonText: t("common.Confirm", {
        defaultValue: "Confirm",
      }),
      cancelButtonText: t("common.Cancel", {
        defaultValue: "Cancel",
      }),
      cancelButtonType: "text",
      cancelError: true,
      typeNew: true,
      async onApply() {
        try {
          const res = await getAuthConnectedAccountMutation({
            linkedUserId: accountId,
          }).unwrap();
          const token = res?.token;

          if (token) {
            setLocalStorageObject(LocalStorageKeys.AuthToken, token);
            window.location.href = "/home";
          }
        } catch (e: any) {
          console.log("error", e);
          setSnackBar({
            type: "error",
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };

  return {
    loginAsAccount,
    backToMyAccount,
    userLinkedAccounts,
    isUserLinkedAccountsLoading,
    userConnectedAccounts,
    isUserConnectedAccountsLoading,
    connectedAccountsToShow: userConnectedAccounts?.filter(ca => ca.status === 'APPROVED')
  }
}
