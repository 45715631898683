import { useSubUserMEControllerQuery, useUpdateSubUserControllerMutation, useUpdateSubUserPhoneNumberControllerMutation, useUpdateSubUserAddressControllerMutation } from "api/endpoints/subProfiles";
import { useCallback } from "react";
import { EditType } from "../accountSettings/types";
import { User } from "../personalInformation/types";
import { useSaveAddressControllerMutation, useSaveEmailControllerMutation, useSavePhoneNumberControllerMutation } from "api/endpoints/account";

export const useUpdateSubUser = () => {
  const { data: subUserInfo } = useSubUserMEControllerQuery({});
  const [updateSubUserMutation, { isLoading: updateSubUserMutationIsLoading }] =
    useUpdateSubUserControllerMutation();
	const [updateSubUserPhoneNumberMutation,
      { isLoading: updateSubUserPhoneNumberIsLoading },
    ] = useUpdateSubUserPhoneNumberControllerMutation();
   
  const [
    updateSubUserAddressMutation,
    { isLoading: updateSubUserAddressMutationIsLoading },
  ] = useUpdateSubUserAddressControllerMutation();
  
  const updateSubUser = useCallback(async (newUser: Partial<User> , mode: EditType) => {
    switch (mode) {
      case EditType.Email:
        await updateSubUserMutation({
          email: newUser.email ?? '',
          givenName: subUserInfo.givenName,
          familyName: subUserInfo.familyName,
          department: subUserInfo.department,
          subProfileId: subUserInfo.id,
        }).unwrap();
        break;
      case EditType.Phone:
        await updateSubUserPhoneNumberMutation({
          phoneNumber: newUser.phoneNumber ?? '',
          subProfileId: subUserInfo.id,
          phoneNumberCountryCode: subUserInfo.phoneNumberCountryCode,
        }).unwrap();
        break;
      case EditType.Address:
        await updateSubUserAddressMutation({
          subProfileId: subUserInfo?.id,
          countryCodeAlpha3: subUserInfo?.countryCodeAlpha3,
          updatePaymentCardBillingAddress: false,
          locality: newUser.city ?? '',
          streetAddress: newUser.streetAddress ?? '',
          postalCode: newUser.postalCode ?? '',
          state: Number(newUser.region?.value),
          extendedAddress: newUser.extendedAddress ?? '',
        }).unwrap();
        break;
    }
  }, []);
  
  const isLoading = updateSubUserPhoneNumberIsLoading || updateSubUserAddressMutationIsLoading || updateSubUserMutationIsLoading;
  
  return { updateSubUser, isLoading }
}

export const useUpdateConsumer = () => {
  const [saveEmailControllerMutation, 
    { isLoading: saveEmailMutationIsLoading },
  ] = useSaveEmailControllerMutation();
	const [savePhoneNumberControllerMutation, 
    { isLoading: savePhoneNumberMutationIsLoading },
  ] = useSavePhoneNumberControllerMutation();
	const [saveAddressControllerMutation, 
    { isLoading: saveAddressMutationIsLoading },
  ] = useSaveAddressControllerMutation();

  const updateConsumer = useCallback(async (newUser: Partial<User> , mode: EditType) => {
      switch (mode) {
        case EditType.Email:
          await saveEmailControllerMutation({ email: newUser.email ?? '' }).unwrap();
          break;
        case EditType.Phone:
          await savePhoneNumberControllerMutation({ phone: newUser.phoneNumber ?? '' }).unwrap();
          break;	
        case EditType.Address:
          await saveAddressControllerMutation({ 
            streetAddress: newUser.streetAddress ?? '',
            extendedAddress: newUser.extendedAddress ?? '',
            postalCode: newUser.postalCode ?? '',
            state: Number(newUser.region?.value),
            city: newUser.city ?? ''
          }).unwrap();
          break;
        }
  }, []);

  const isLoading = saveEmailMutationIsLoading || savePhoneNumberMutationIsLoading || saveAddressMutationIsLoading;

  return { updateConsumer, isLoading };
}