import { User } from "./types";
import { states } from 'constants/data';

export function prepareUser(userInfo: any): User {
    const region = states.find((state) => state.value === userInfo.state);

    return ({
      countryCodeAlpha3: userInfo?.countryCodeAlpha3 ?? '',
      email: userInfo?.email ?? '',
      postalCode: userInfo?.postalCode ?? '',
      streetAddress: userInfo?.streetAddress ?? '',
      region: region ?? null,
      fullName: `${userInfo?.givenName} ${userInfo?.familyName}` ?? '',
      city: userInfo?.city ?? '',
      phoneNumber: userInfo?.phoneNumber ?? '',
      extendedAddress: userInfo?.extendedAddress ?? '',
    })
  }