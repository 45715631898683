import TabsPanel from '../../../../../../../shared/components/containers/customContainer/componetns/tabsPanel';
import { ItemArr } from '../../../../../../../shared/components/containers/customContainer';
import {
  NotificationsDrawerMode,
  NotificationSettingsDrawerTab,
} from '../../types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRoutes, SettingsRouteSubRoute } from '../../../../../../../routes';
import { NOTIFICATIONS } from '../../../../securitySearchParams';
import { SubUsers } from './SubUsers';
import { BasicSettings } from './BasicSettings';
import { useMemo } from 'react';
import { useCurrentUser } from '../../../../../../../providers/user.provider';

const TAB_KEY = 'not_settings_tab';

export const NotificationSettingsDrawerContent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isConsumer, isSubUser } = useCurrentUser();
  const handleNavigate = (id: string) => {
    navigate(
      appRoutes.settings(SettingsRouteSubRoute.security, {
        page: NOTIFICATIONS,
        drawerMode: NotificationsDrawerMode.notificationsSettings,
        [TAB_KEY]: id,
      }),
      { replace: true }
    );
  };

  const tabs: ItemArr[] = useMemo(() => {
    return [
      {
        id: NotificationSettingsDrawerTab.basic,
        label: 'basic settings',
        isShow: true,
        img: '',
      },
      !isConsumer && !isSubUser && {
        id: NotificationSettingsDrawerTab.authorizedUsers,
        label: 'Authorized users',
        isShow: true,
        img: '',
      },
    ].filter(Boolean) as ItemArr[];
  }, [])

  const currentTab = searchParams.get(TAB_KEY) || NotificationSettingsDrawerTab.basic;

  return (
    <>
      <TabsPanel
        handleNavigate={handleNavigate}
        tabsArr={tabs}
        page={currentTab}
      />
      <div style={{ paddingTop: '16px' }}>
        {currentTab === NotificationSettingsDrawerTab.basic && <BasicSettings />}
        {currentTab === NotificationSettingsDrawerTab.authorizedUsers && (
          <SubUsers />
        )}
      </div>
    </>
  );
};
