import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authApi } from '../api/endpoints/auth'
import { userApi } from '../api/endpoints/user'
import { enumsApi } from '../api/endpoints/enums'
import { financialAccountsApi } from '../api/endpoints/financialAccounts'
import { paymentCardsApi } from '../api/endpoints/paymentCards'
import { accountApi } from '../api/endpoints/account'
import { fundsMovementApi } from '../api/endpoints/fundsMovement'
import { settingsApi } from '../api/endpoints/settings'
import { transactionApi } from '../api/endpoints/transaction'
import { statementsApi } from '../api/endpoints/statements'
import { transactionEventsApi } from '../api/endpoints/transactionEvents'
import { subProfilesApi } from '../api/endpoints/subProfiles'
import { usersManagementApi } from '../api/endpoints/usersManagement'
import { supportApi } from '../api/endpoints/support'
import { ticketsApi } from '../api/endpoints/tickets'
import loginSlice from './login/loginSlice'
import { rewardsApi } from '../api/endpoints/rewards'
import { spendRulesApi } from 'api/endpoints/spendRules'
import { linkedAccountApi } from 'api/endpoints/linkedAccount'
import { searchApi } from 'api/endpoints/search'
import { rewardsIntegrationsApi } from 'api/endpoints/rewardIntegrations'
import { lendingFrontApi } from 'api/endpoints/lendingFront'
import { partnersApi } from 'api/endpoints/partners'
import { OrderOutCardModel } from 'entities/orderout-card'
import { ToMyOwnAccountModel } from 'features/transfers/transfer-to-my-own-account'
import { ToNeteviaCustomerModel } from 'features/transfers/transfer-to-netevia-customer'
import { AchTransferModel } from 'features/transfers/transfer-ach'
import { scheduledTransferApi } from 'api/endpoints/scheduledTransfer'
import { FromConnectedAccountModel } from 'features/transfers/transfer-from-connected-account'
import { BatchTransferModel } from 'features/transfers/transfer-batch'
import { notificationsApi } from 'api/endpoints/notifications'
import { giftCardsApi } from 'api/endpoints/giftCards'
import { userReportApi } from 'api/endpoints/userReport'
import { pinWheelApi } from 'api/endpoints/pinWheel'

export const rootReducer = combineReducers({
  login: loginSlice,
  [OrderOutCardModel.slice.name]: OrderOutCardModel.slice.reducer,
  [ToMyOwnAccountModel.slice.name]: ToMyOwnAccountModel.slice.reducer,
  [ToNeteviaCustomerModel.slice.name]: ToNeteviaCustomerModel.slice.reducer,
  [AchTransferModel.slice.name]: AchTransferModel.slice.reducer,
  [FromConnectedAccountModel.slice.name]:
    FromConnectedAccountModel.slice.reducer,
  [BatchTransferModel.slice.name]: BatchTransferModel.slice.reducer,

  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [enumsApi.reducerPath]: enumsApi.reducer,
  [financialAccountsApi.reducerPath]: financialAccountsApi.reducer,
  [paymentCardsApi.reducerPath]: paymentCardsApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [fundsMovementApi.reducerPath]: fundsMovementApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [statementsApi.reducerPath]: statementsApi.reducer,
  [transactionEventsApi.reducerPath]: transactionEventsApi.reducer,
  [subProfilesApi.reducerPath]: subProfilesApi.reducer,
  [usersManagementApi.reducerPath]: usersManagementApi.reducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [ticketsApi.reducerPath]: ticketsApi.reducer,
  [rewardsApi.reducerPath]: rewardsApi.reducer,
  [spendRulesApi.reducerPath]: spendRulesApi.reducer,
  [linkedAccountApi.reducerPath]: linkedAccountApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [rewardsIntegrationsApi.reducerPath]: rewardsIntegrationsApi.reducer,
  [lendingFrontApi.reducerPath]: lendingFrontApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [scheduledTransferApi.reducerPath]: scheduledTransferApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [giftCardsApi.reducerPath]: giftCardsApi.reducer,
  [userReportApi.reducerPath]: userReportApi.reducer,
  [pinWheelApi.reducerPath]: pinWheelApi.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, composeWithDevTools: true }).concat(
      authApi.middleware,
      userApi.middleware,
      enumsApi.middleware,
      financialAccountsApi.middleware,
      paymentCardsApi.middleware,
      accountApi.middleware,
      fundsMovementApi.middleware,
      settingsApi.middleware,
      transactionApi.middleware,
      statementsApi.middleware,
      transactionEventsApi.middleware,
      subProfilesApi.middleware,
      usersManagementApi.middleware,
      supportApi.middleware,
      ticketsApi.middleware,
      rewardsApi.middleware,
      spendRulesApi.middleware,
      linkedAccountApi.middleware,
      searchApi.middleware,
      rewardsIntegrationsApi.middleware,
      lendingFrontApi.middleware,
      partnersApi.middleware,
      scheduledTransferApi.middleware,
      notificationsApi.middleware,
      giftCardsApi.middleware,
      userReportApi.middleware,
      pinWheelApi.middleware
    ),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export { store }
