import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SimpleContainer from "shared/components/containers/simpleContainer";
import { AnimateWrapper } from "components/animate-wrapper";
import {
  useDefaultTransfersFinAccountControllerQuery,
  useChangeDefaultTransfersFinAccountControllerMutation,
} from "api/endpoints/settings";
import { useFinancialAccountsControllerQuery } from "api/endpoints/financialAccounts";
import styles from "./style.module.scss";
import openAccIcon from "assets/svg/open-new-account.svg";
import ChooseAccList from "./components/choseAccList";
import { useConfirmDialog } from "../../../providers/confirm.provider";
import YourPrimaryAccList from "./components/yourPrimaryAccList";
import WarningModal from "components/modals/WarningModal";
import helper from "services/helper";
import { useMediaQuery, useTheme } from "@mui/material";
import DrawerModal from "shared/components/drawerModal";
import NewAccount from "shared/components/newAccount";
import ArrowLeft from "assets/svg/arrow-left-gray.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "shared/ui/buttons";

const PrimaryAccount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const { t } = useTranslationProvider();
  const page = searchParams.get("page") || "";
  const confirm = useConfirmDialog();
  const { data, isFetching: primaryAccIsLoading } =
    useDefaultTransfersFinAccountControllerQuery({});
  const [
    changeFinAccountMutation,
    { isLoading: changeFinAccountMutationIsLading },
  ] = useChangeDefaultTransfersFinAccountControllerMutation();
  const {
    data: financialAccountsData,
    isFetching: financialAccountsIsLoading,
  } = useFinancialAccountsControllerQuery({});
  const financialAccounts = financialAccountsData?.financialAccounts;

  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);
  const [textWarning, setTextWarning] = useState<string>();

  const primaryAcc = financialAccounts?.find(
    (account) => account.financialAccountId === data?.finAccountId
  );
  const activeAccounts = financialAccounts?.filter(
    (account) =>
      account.status === "ACTIVE" &&
      account.financialAccountId !== data?.finAccountId
  );

  const handleNewAccount = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.append("mode", "NewAccount");
    newSearchParams.append("title", "NewAccount");
    setSearchParams(newSearchParams);
  };

  const handleChangePrimaryAcc = (id) => {
    const accFrom = primaryAcc?.name;
    const accTo = financialAccounts?.find(
      (account) => account?.financialAccountId === id
    )?.name;

    confirm.show({
      applyButtonText: "Confirm",
      dialogTitle: "Primary account change",
      dialogText: `You're about to switch your primary account from "${accFrom}" to "${accTo}". To proceed, simply press "Confirm”.`,
      cancelButtonType: "text",
      cancelError: true,
      async onApply() {
        try {
          await changeFinAccountMutation({
            finAccountId: id,
          }).unwrap();
        } catch (e: any) {
          console.log("erroe", e);
          setTextWarning(
            helper.formatErrors(e?.data) ||
              "Something went wrong! Please try again later or contact our support"
          );
          setIsShowWarning(true);
        }
      },
    });
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        {!matches && (
          <div className={styles.container_nav}>
            <img src={ArrowLeft} onClick={() => setSearchParams({})} />
            <span className={styles.container_nav_title}>
              {t("Settings.Company information", {
                defaultValue: "Company information",
              })}
            </span>
          </div>
        )}
        <Button onClick={handleNewAccount} >+ OPEN NEW ACCOUNT</Button>
        <div>
          <YourPrimaryAccList
            isLoading={primaryAccIsLoading}
            item={primaryAcc}
          />
          <ChooseAccList
            handleSumbit={handleChangePrimaryAcc}
            arr={activeAccounts || []}
            isLoading={financialAccountsIsLoading}
            sumbitIsLoading={changeFinAccountMutationIsLading}
          />
        </div>
      </div>
      <WarningModal
        titleMassage={textWarning}
        onClose={() => setIsShowWarning(false)}
        isOpen={isShowWarning}
      />
      <DrawerModal
        isShow={searchParams.get("mode") === "NewAccount"}
        onClose={() => setSearchParams({ page })}
        goBack={undefined}
      >
        <NewAccount />
      </DrawerModal>
    </AnimateWrapper>
  );
};

export default PrimaryAccount;
