import { useMediaQuery, useTheme } from '@mui/material';
import { useCurrentUser } from '../../providers/user.provider';
import { useFinancialAccounts } from '../../api/hooksApi/useFinancialAccounts';
import { AnimateWrapper } from '../../components/animate-wrapper';
import { AccountList } from './components/accountList';
import styled, { css } from 'styled-components';
import AccountBalance from './components/accountBalance';
import { usePermission } from 'shared/hooks/usePermission';
import CardList from './components/cardList';
import { LoginAs } from './components/loginAs';
import { HomeDiagrams } from './components/diagrams';
import { HomeSlider } from './components/homeSlide';

const Home = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up('sm'));
  const { cashflowPermission, homeBannerPermission } = usePermission();
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const { isSubUser, subUserRights, isConsumer } = useCurrentUser();

  return (
    <AnimateWrapper className='fade'>
      <Container>
        <LoginAs />
        <BoxInfo>
          <AccountBalance />
          {cashflowPermission && <HomeDiagrams />}
          {matches && homeBannerPermission && <HomeSlider />}
        </BoxInfo>
        <AccountList />
        {isSubUser &&
          subUserRights({ viewAllCardsAndFinancialAccounts: false }) && (
            <CardList />
          )}
        {!matches && homeBannerPermission && <HomeSlider />}
      </Container>
    </AnimateWrapper>
  );
};
export default Home;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BoxInfo = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  `}
`;
