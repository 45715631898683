import { useSearchParams } from "react-router-dom";
import { states } from "constants/data";
import helper from "services/helper";
import { useSnackBar } from "providers/snackBar.provider";
import { AnimateWrapper } from "components/animate-wrapper";
import { EditType } from "pages/settings/accountSettings/types";
import EditBillingAddress from "pages/settings/changeCompanyInformation/components/editBillingAddress";
import EditEmailaddress from "pages/settings/changeCompanyInformation/components/editEmailaddress";
import EditPhoneNumber from "pages/settings/changeCompanyInformation/components/editPhoneNumber";
import { User } from "pages/settings/personalInformation/types";
import { useAccount } from "pages/settings/accountSettings/provider";
import { schemaEmail, schemaPhone } from "./schemaValidations";
import { EditEmployerInformation } from '../../../changeCompanyInformation/components/editEmployerInformation';

interface ChangePersonalInformationProps {
  user: User
  updateUser: (newUser: Partial<User>, mode: EditType) => Promise<void>
  isLoading: boolean;
}

const ChangePersonalInformation = ({ user, isLoading, updateUser }: ChangePersonalInformationProps) => {
  const { setSnackBar } = useSnackBar();
  const { setIsShowDrawer, } = useAccount();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");

  const handleSumbitEmail = async (form) => {
    try {
      await updateUser({ email: form.email }, EditType.Email);
      setIsShowDrawer(false);
      setSnackBar({
        type: "success",
        message: "Email is updated",
        isShow: true,
      });
    } catch (e: any) {
      setIsShowDrawer(false);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleSumbitPhone = async (form) => {
    try {
      await updateUser({ phoneNumber: form.phone }, EditType.Phone);
      setIsShowDrawer(false);
      setSnackBar({
        type: "success",
        message: "Phone is updated",
        isShow: true,
      });
    } catch (e: any) {
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleSubmitAddress = async (form) => {
    try {
      const newState = states.find((i) => i.label === form.state);
      await updateUser({
        streetAddress: form.streetAddress,
        extendedAddress: form.extendedAddress,
        city: form.city,
        postalCode: form.zip,
        region: newState,
      }, EditType.Address);
      setIsShowDrawer(false);
      setSnackBar({
        type: "success",
        message: "Address is updated",
        isShow: true,
      });
    } catch (e: any) {
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return (
    <AnimateWrapper className="fade">
      <div>
        {mode === EditType.Email && (
          <EditEmailaddress
            email={user.email}
            handleSubmit={handleSumbitEmail}
            isLoading={isLoading}
          />
        )}
         {mode === EditType.Phone && (
          <EditPhoneNumber
            phone={user.phoneNumber}
            handleSubmit={handleSumbitPhone}
            isLoading={isLoading}
          />
        )}
        {mode === EditType.Address && (
          <EditBillingAddress
            streetAddress={user.streetAddress}
            extendedAddress={user.extendedAddress}
            city={user.city}
            state={user.region?.label || ''}
            zip={user.postalCode}
            handleSubmit={handleSubmitAddress}
            isLoading={isLoading}
          />
        )}
        {mode === EditType.EmployerInformation && (
          <EditEmployerInformation onCloseDrawer={() => setIsShowDrawer(false)} />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default ChangePersonalInformation;
