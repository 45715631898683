import { useHolderV2ControllerQuery } from "api/endpoints/account";
import { useUpdateConsumer } from "../../hooks";
import { prepareUser } from "pages/settings/personalInformation/helper";
import ChangePersonalInformation from "../ChangePersonalInformation";
import { Skeleton } from "components/skeleton";

const ConsumerChangePersonalInformation = () => {
  const { data: consumerInfo } = useHolderV2ControllerQuery();
  const { updateConsumer, isLoading } = useUpdateConsumer();
  
  if (!consumerInfo) {
    return <Skeleton width='100%' height='200px' />
  }
  
  const preparedUser = prepareUser({
    ...consumerInfo.accountHolderInformation,
    phoneNumber: consumerInfo.accountHolderInformation?.phone,
    city: consumerInfo.accountHolderInformation?.locality,
  })
  
  return <ChangePersonalInformation 
    user={preparedUser}
    isLoading={isLoading}
    updateUser={updateConsumer}
  />
};

export default  ConsumerChangePersonalInformation;
  